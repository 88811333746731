import React, { createContext, useState, useEffect } from 'react';
import jwtDecode from 'jwt-decode';
import { useNavigate } from 'react-router-dom';

export const AuthContext = createContext();

export function AuthProvider({ children }) {
  const [user, setUser] = useState(null);
  const navigate = useNavigate();

  const login = (token) => {
    try {
      const decoded = jwtDecode(token);
      const expirationTime = decoded.exp * 1000; // `exp` is in seconds, convert to ms

      if (Date.now() > expirationTime) {
        throw new Error('Token is already expired.');
      }

      localStorage.setItem('token', token);
      localStorage.setItem('tokenExpiration', expirationTime.toString());

      setUser(decoded);
    } catch (error) {
      console.error('Error decoding token:', error);
      logout();
    }
  };

  const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('tokenExpiration');
    setUser(null);
    navigate('/login');
  };

  const checkTokenExpiration = () => {
    const expirationTime = localStorage.getItem('tokenExpiration');
    if (expirationTime && Date.now() > Number(expirationTime)) {
      console.warn('Token expired. Logging out.');
      logout();
    }
  };

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      try {
        const decoded = jwtDecode(token);
        const expirationTime = decoded.exp * 1000; // `exp` is in seconds, convert to ms

        if (Date.now() < expirationTime) {
          setUser(decoded);
        } else {
          console.warn('Token expired.');
          logout();
        }
      } catch (error) {
        console.error('Error decoding token:', error);
        logout();
      }
    }

    // Check token expiration every minute
    const interval = setInterval(checkTokenExpiration, 60 * 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <AuthContext.Provider value={{ user, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
}
