import React, { useState, useEffect } from 'react';
import { getContacts, deleteContact } from '../services/contactService';
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Container,
  Box,
  IconButton,
  Paper,
  Card,
  CardContent,
  CardActions,
  Grid,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import DashboardLayout from '../components/DashboardLayout';

function ContactsPage() {
  const [contacts, setContacts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    fetchContacts();
  }, []);

  const fetchContacts = async () => {
    try {
      const data = await getContacts();
      setContacts(data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching contacts:', error);
      setError('Failed to load contacts.');
      setLoading(false);
    }
  };

  const handleDelete = async (contactId) => {
    const confirmed = window.confirm('Are you sure you want to delete this contact?');
    if (confirmed) {
      try {
        await deleteContact(contactId);
        setContacts(contacts.filter((contact) => contact.contactId !== contactId));
      } catch (error) {
        console.error('Error deleting contact:', error);
        setError('Failed to delete contact.');
      }
    }
  };

  return (
    <DashboardLayout>
      <Container>
        <Box mt={4}>
          <Typography variant="h4" gutterBottom>
            Contacts
          </Typography>

          {loading ? (
            <Typography>Loading...</Typography>
          ) : error ? (
            <Typography color="error">{error}</Typography>
          ) : isMobile ? (
            <Grid container spacing={2}>
              {contacts.map((contact) => (
                <Grid item xs={12} key={contact.contactId}>
                  <Card variant="outlined" sx={{ mb: 2 }}>
                    <CardContent>
                      <Typography variant="h6">{contact.name}</Typography>
                      <Typography>Email: {contact.email}</Typography>
                      <Typography>Company: {contact.company || 'N/A'}</Typography>
                      <Typography>Country: {contact.country}</Typography>
                      <Typography>Phone: {contact.phone || 'N/A'}</Typography>
                      <Typography>Message: {contact.message}</Typography>
                      <Typography>Products Interested: {contact.productsInterested || 'N/A'}</Typography>
                      <Typography>
                        Date Sent:{' '}
                        {contact.submittedAt
                          ? new Date(contact.submittedAt).toLocaleString()
                          : 'N/A'}
                      </Typography>
                    </CardContent>
                    <CardActions>
                      <IconButton
                        aria-label="delete"
                        onClick={() => handleDelete(contact.contactId)}
                        color="error"
                      >
                        <DeleteIcon />
                      </IconButton>
                    </CardActions>
                  </Card>
                </Grid>
              ))}
            </Grid>
          ) : (
            <Paper elevation={3} sx={{ width: '100%', overflow: 'hidden' }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell>Company</TableCell>
                    <TableCell>Country</TableCell>
                    <TableCell>Phone</TableCell>
                    <TableCell>Message</TableCell>
                    <TableCell>Products Interested</TableCell>
                    <TableCell>Date Sent</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {contacts.map((contact) => (
                    <TableRow key={contact.contactId}>
                      <TableCell>{contact.name}</TableCell>
                      <TableCell>{contact.email}</TableCell>
                      <TableCell>{contact.company || 'N/A'}</TableCell>
                      <TableCell>{contact.country}</TableCell>
                      <TableCell>{contact.phone || 'N/A'}</TableCell>
                      <TableCell>{contact.message}</TableCell>
                      <TableCell>{contact.productsInterested || 'N/A'}</TableCell>
                      <TableCell>
                        {contact.submittedAt
                          ? new Date(contact.submittedAt).toLocaleString()
                          : 'N/A'}
                      </TableCell>
                      <TableCell>
                        <IconButton
                          aria-label="delete"
                          onClick={() => handleDelete(contact.contactId)}
                          color="error"
                        >
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Paper>
          )}
        </Box>
      </Container>
    </DashboardLayout>
  );
}

export default ContactsPage;
